import Image from 'next/future/image';

import productPlaceholder from '../../assets/product-placeholder.svg';

export const ProductPlaceholder = ({
  aspectRatio = 'auto',
  priority,
}: {
  aspectRatio?: string;
  priority?: boolean;
}) => {
  return (
    <Image
      src={productPlaceholder}
      alt="product placeholder"
      priority={priority}
      style={{ width: '100%', height: 'auto', aspectRatio: aspectRatio }}
    />
  );
};
