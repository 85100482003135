import { Group, Text } from '@mantine/core';
import { useMemo } from 'react';

import { usePrice } from '../../hooks';
import { ValidLocales } from '../../typings';
import { getPriceRangeWithTemplate } from '../../utils';

type Props = {
  price:
    | {
        original: number;
        salePrice: number | null;
      }
    | {
        original: number;
        salePrice: {
          minPrice: number;
          maxPrice: number;
        };
      }
    | {
        original: {
          minPrice: number;
          maxPrice: number;
        };
        salePrice: number;
      }
    | {
        original: {
          minPrice: number;
          maxPrice: number;
        };
        salePrice: {
          minPrice: number;
          maxPrice: number;
        } | null;
      };
  spacing?: number;
  currency?: string;
  priceDecimalPoints?: number;
  priceTemplate?: string;
  locale?: ValidLocales;
};

export const ProductPrice = ({
  price,
  spacing = 10,
  currency,
  priceDecimalPoints,
  priceTemplate,
  locale,
}: Props) => {
  const { formatPrice } = usePrice({ locale, currency, priceDecimalPoints });
  const isOnSale = useMemo(() => (price ? !!price.salePrice : false), [price]);

  return (
    <Group my={spacing} position="apart" style={{ justifyContent: 'flex-start', gap: 10 }}>
      {isOnSale && (
        <Text size="price-fsize" weight={500} color="price" strikethrough>
          {typeof price.original === 'object'
            ? getPriceRangeWithTemplate({ priceRange: price.original, priceTemplate, formatPrice })
            : formatPrice(price.original)}
        </Text>
      )}
      <Text size="price-fsize" weight={900} color={isOnSale ? 'sale-price' : 'price'}>
        {isOnSale && price.salePrice
          ? typeof price.salePrice === 'object'
            ? getPriceRangeWithTemplate({ priceRange: price.salePrice, priceTemplate, formatPrice })
            : formatPrice(price.salePrice)
          : typeof price.original === 'object'
            ? getPriceRangeWithTemplate({ priceRange: price.original, priceTemplate, formatPrice })
            : formatPrice(price.original)}
      </Text>
    </Group>
  );
};
