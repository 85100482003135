import { Header as MantineHeader } from '@mantine/core';
import { useEffect, useState } from 'react';

import { HeaderBasicProps } from '../../../typings';
import { hasEnabledCompanyLinks } from '../../../utils';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export interface HeaderProps extends HeaderBasicProps {
  isMobile?: boolean;
}

export const Header = ({
  isMobile,
  stickyHeader,
  bgColor,
  stickyBgColor,
  stickyBorderColor,
  showSocialMediaIcons,
  company,
  ...props
}: HeaderProps) => {
  const showSocialMediaIconsFlag = showSocialMediaIcons && hasEnabledCompanyLinks(company);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        const parent = document.querySelector('.scroll-container');

        if (parent) {
          setScrolled(parent.scrollTop > 50);
        } else {
          setScrolled(window.scrollY > 50);
        }
      };

      document.addEventListener('scroll', handleScroll, { passive: true, capture: true });

      return () => document.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <MantineHeader
      height="auto"
      pos={stickyHeader === 'Always' ? 'sticky' : 'relative'}
      withBorder={stickyHeader === 'Always' && scrolled}
      style={{
        transition: 'background-color 0.3s ease, border-color 0.3s ease',
        borderBottomColor:
          stickyHeader === 'Always' && scrolled ? stickyBorderColor : 'transparent',
        backgroundColor: stickyHeader === 'Always' && scrolled ? stickyBgColor : bgColor,
      }}
    >
      {isMobile ? (
        <MobileHeader
          scrolled={scrolled}
          stickyHeader={stickyHeader}
          bgColor={bgColor}
          stickyBgColor={stickyBgColor}
          showSocialMediaIcons={showSocialMediaIconsFlag}
          company={company}
          {...props}
        />
      ) : (
        <DesktopHeader
          scrolled={scrolled}
          stickyHeader={stickyHeader}
          bgColor={bgColor}
          stickyBgColor={stickyBgColor}
          showSocialMediaIcons={showSocialMediaIconsFlag}
          company={company}
          {...props}
        />
      )}
    </MantineHeader>
  );
};
