import { ContextStylesParams, MantineTheme, MantineThemeOverride } from '@mantine/core';

import { IPage, IShopPage, ITheme } from '../typings';
import { getDesignDirection } from '.';

const createThemeColor = (color: string) => {
  const colorValues = new Array<string>(10);
  return colorValues.fill(color) as keyof MantineThemeOverride['colors'];
};

// TODO: update text & buttons colors keys & values, plus other components after integrating all shops

export const createShopTheme = (
  shopTheme: ITheme<IPage | IShopPage>,
  locale?: string,
): MantineThemeOverride => {
  return {
    dir: getDesignDirection(locale),

    // dynamic theming
    colors: {
      black: createThemeColor('#121118'),
      'secondary-white': createThemeColor('#F4F4F4'),
      'light-gray': createThemeColor('#EDEFF3'),
      'dark-gray': createThemeColor('#404C60'),
      'high-gray': createThemeColor('#5D5D69'),
      'med-gray': createThemeColor('#A8A8A8'),
      'low-gray': createThemeColor('#CDCCD7'),
      'semi-transparent-gray': createThemeColor('#edeff369'),
      'dark-red': createThemeColor('#C13749'),
      'primary-green': createThemeColor('#45B467'),
      'page-bg': createThemeColor(shopTheme.pageBgColor || '#ffffff'),
      'btn-primary': createThemeColor(shopTheme.primaryBtnBgColor),
      'btn-primary-label': createThemeColor(shopTheme.primaryBtnLabelColor),
      'btn-primary-border': createThemeColor(shopTheme.primaryBtnBorderColor),
      'btn-secondary': createThemeColor(shopTheme.secondaryBtnBgColor),
      'btn-secondary-label': createThemeColor(shopTheme.secondaryBtnLabelColor),
      'btn-secondary-border': createThemeColor(shopTheme.secondaryBtnBorderColor),
      'nav-bg': createThemeColor(shopTheme.primaryBtnBgColor),
      'borders-and-lines': createThemeColor(shopTheme.pageBordersAndLinesColor),
      'headings-and-links': createThemeColor(shopTheme.headingsAndLinksColor),
      'body-text': createThemeColor(shopTheme.bodyTextColor),
      price: createThemeColor(shopTheme.priceColor),
      'sale-price': createThemeColor(shopTheme.salePriceColor),
      'input-bg': createThemeColor(shopTheme.formFieldsBgColor),
      'input-border': createThemeColor(shopTheme.formFieldsBorderColor || '#404C60'),
      'input-text': createThemeColor(shopTheme.formFieldsLabelColor || '#404C60'),
    },
    defaultRadius: shopTheme.radius,
    fontFamily: `"${
      (locale === 'ar' ? shopTheme.arabicFont : shopTheme.englishFont) || 'Inter'
    }" !important`,

    headings: {
      fontFamily: `"${
        (locale === 'ar' ? shopTheme.arabicFont : shopTheme.englishFont) || 'Inter'
      }" !important`,
      sizes: {
        h1: {
          fontSize: `${locale === 'ar' ? shopTheme.arabicSizes?.h1 : shopTheme.englishSizes?.h1}px`,
        },
        h2: {
          fontSize: `${locale === 'ar' ? shopTheme.arabicSizes?.h2 : shopTheme.englishSizes?.h2}px`,
        },
        h3: {
          fontSize: `${locale === 'ar' ? shopTheme.arabicSizes?.h3 : shopTheme.englishSizes?.h3}px`,
        },
        h4: {
          fontSize: `${locale === 'ar' ? shopTheme.arabicSizes?.h4 : shopTheme.englishSizes?.h4}px`,
        },
      },
    },
    cursorType: 'pointer',

    fontSizes: {
      'product-name-fsize': `${
        (locale === 'ar' ? shopTheme.arabicSizes?.h4 : shopTheme.englishSizes?.h4) || 16
      }px !important`,
      'price-fsize': `${
        (locale === 'ar' ? shopTheme.arabicSizes?.body : shopTheme.englishSizes?.body) || 14
      }px`,
    },

    // components theming
    components: {
      Skeleton: {
        styles: (theme: MantineTheme) => ({
          root: {
            backgroundColor: theme.colors['light-gray'][0],
          },
        }),
      },
      Button: {
        defaultProps: {
          loaderPosition: 'right',
        },
        styles: (theme: MantineTheme, _, context: ContextStylesParams) => ({
          root: {
            fontSize: locale === 'ar' ? shopTheme.arabicSizes?.btn : shopTheme.englishSizes?.btn,
            fontWeight: 400,
            textTransform: 'capitalize',

            color:
              context.variant === 'filled'
                ? shopTheme.primaryBtnLabelColor
                : shopTheme.secondaryBtnLabelColor,
            backgroundColor:
              context.variant === 'filled'
                ? shopTheme.primaryBtnBgColor
                : shopTheme.secondaryBtnBgColor,
            borderColor:
              context.variant === 'filled'
                ? shopTheme.primaryBtnBorderColor
                : shopTheme.secondaryBtnBorderColor,

            '&:hover': {
              textDecoration: 'none',
              '&[href]': {
                color:
                  context.variant === 'filled'
                    ? shopTheme.primaryBtnLabelColor
                    : shopTheme.secondaryBtnLabelColor,
              },
              backgroundColor:
                context.variant === 'filled'
                  ? shopTheme.primaryBtnBgColor
                  : shopTheme.secondaryBtnBgColor,
            },

            '&[data-disabled]': {
              color: theme.white,
              backgroundColor: theme.colors['low-gray'][0],
            },
          },
        }),
      },
      TextInput: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
            color: theme.colors['body-text'][0],
          },
          input: {
            padding: '10px 20px',
            color: theme.colors['input-text'][0],
            backgroundColor: theme.colors['input-bg'][0],
            borderColor: theme.colors['input-border'][0],

            '&:focus': {
              borderColor: theme.colors['input-border'][0],
            },

            '&::placeholder': {
              fontSize: '16px',
              fontWeight: 400,
              color: theme.colors['input-text'][0],
              opacity: 1,
            },
          },
        }),
      },
      Textarea: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
          },
          input: {
            padding: '10px 20px',
            color: theme.colors['input-text'][0],
            borderColor: theme.colors['input-border'][0],
            backgroundColor: theme.colors['input-bg'][0],

            '&:focus': {
              borderColor: theme.colors['input-border'][0],
            },

            '&::placeholder': {
              fontSize: '16px',
              fontWeight: 400,
              color: theme.colors['input-text'][0],
              opacity: 1,
            },
          },
        }),
      },
      PasswordInput: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
          },
          input: {
            color: theme.colors['input-text'][0],
            borderColor: theme.colors['input-border'][0],
            backgroundColor: theme.colors['input-bg'][0],

            '&:focus': {
              borderColor: theme.colors['input-border'][0],
            },
            '&:focus-within': {
              borderColor: theme.colors['input-border'][0],
            },
          },
          innerInput: {
            padding: '10px 20px',
            '&::placeholder': {
              fontSize: '16px',
              fontWeight: 400,
              color: theme.colors['input-text'][0],
              opacity: 1,
            },
          },
          rightSection: {
            paddingRight: '20px',
          },
        }),
      },
      Select: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
            color: theme.colors['body-text'][0],
          },
          rightSection: { pointerEvents: 'none', color: theme.colors['input-border'][0] },
          dropdown: {
            borderColor: theme.colors['input-border'][0],
            backgroundColor: theme.colors['input-bg'][0],
          },
          item: {
            color: `${theme.colors['input-text'][0]} !important`,

            '&[data-selected]': {
              color: `${theme.colors['input-bg'][0]} !important`,
              backgroundColor: theme.colors['input-text'][0],
            },
            '&[data-selected]&[data-hovered]': {
              color: `${theme.colors['input-bg'][0]} !important`,
              backgroundColor: theme.colors['input-text'][0],
            },
            '&[data-hovered]': {
              color: `${theme.colors['input-bg'][0]} !important`,
              backgroundColor: theme.colors['input-text'][0],
            },
          },
          input: {
            padding: '10px 20px',
            color: theme.colors['input-text'][0],
            backgroundColor: theme.colors['input-bg'][0],
            borderColor: theme.colors['input-border'][0],

            '&:focus': {
              borderColor: theme.colors['input-border'][0],
            },

            '&::placeholder': {
              fontSize: '16px',
              fontWeight: 400,
              color: theme.colors['input-text'][0],
              opacity: 1,
            },
          },
        }),
      },
      MultiSelect: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
          },
          rightSection: { pointerEvents: 'none', color: theme.colors['input-border'][0] },
          dropdown: {
            borderColor: theme.colors['input-border'][0],
            backgroundColor: theme.colors['input-bg'][0],
          },
          item: {
            color: `${theme.colors['input-text'][0]} !important`,

            '&[data-selected]': {
              color: `${theme.colors['input-bg'][0]} !important`,
              backgroundColor: theme.colors['input-text'][0],
            },
            '&[data-selected]&[data-hovered]': {
              color: `${theme.colors['input-bg'][0]} !important`,
              backgroundColor: theme.colors['input-text'][0],
            },
            '&[data-hovered]': {
              color: `${theme.colors['input-bg'][0]} !important`,
              backgroundColor: theme.colors['input-text'][0],
            },
          },
          input: {
            color: theme.colors['input-text'][0],
            backgroundColor: theme.colors['input-bg'][0],
            borderColor: theme.colors['input-border'][0],

            '&:focus': {
              borderColor: theme.colors['input-border'][0],
            },

            '&::placeholder': {
              fontSize: '16px',
              fontWeight: 400,
              color: theme.colors['input-text'][0],
              opacity: 1,
            },
          },
        }),
      },
      DatePickerInput: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
          },
          rightSection: { pointerEvents: 'none' },
          day: {
            '&[data-selected]': {
              color: theme.colors['input-text'][0],
              backgroundColor: theme.colors['input-bg'][0],
            },
          },
          placeholder: {
            color: `${theme.colors['input-text'][0]} !important`,
          },
          icon: {
            color: theme.colors['input-text'][0],
          },
          input: {
            padding: '10px 20px',
            color: theme.colors['input-text'][0],
            backgroundColor: theme.colors['input-bg'][0],
            borderColor: theme.colors['input-border'][0],

            '&:focus': {
              borderColor: theme.colors['input-border'][0],
            },

            '&::placeholder': {
              fontSize: 16,
              fontWeight: 400,
              color: theme.colors['input-text'][0],
              opacity: 1,
            },
          },
        }),
      },
      Divider: {
        defaultProps: {
          color: 'borders-and-lines',
        },
      },
      Text: {
        styles: () => ({
          root: {
            textAlign: 'start',
            fontSize: locale === 'ar' ? shopTheme.arabicSizes?.body : shopTheme.englishSizes?.body,
          },
        }),
      },
      Checkbox: {
        defaultProps: {
          color: 'input-border',
        },
        styles: (theme) => ({
          label: {
            color: theme.colors['body-text'][0],
          },
          input: {
            borderColor: theme.colors['input-border'][0],
          },
        }),
      },
      Radio: {
        defaultProps: {
          color: 'input-border',
        },
        styles: (theme) => ({
          label: {
            color: theme.colors['body-text'][0],
          },
          radio: {
            borderColor: theme.colors['input-border'][0],
          },
        }),
      },
      Card: {
        styles: () => ({
          root: {
            borderRadius: 0,
          },
        }),
      },
      Accordion: {
        styles: (theme) => ({
          item: {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.colors['borders-and-lines'][0]} !important`,

            '&[data-active]': {
              backgroundColor: 'transparent',
            },
          },
        }),
      },
      Pagination: {
        styles: (theme) => ({
          control: {
            color: theme.colors['btn-primary-label'][0],
            backgroundColor: theme.colors['btn-primary'][0],

            '&:not([data-disabled]):hover': {
              color: theme.colors['btn-primary'][0],
              backgroundColor: theme.colors['btn-primary-label'][0],
            },

            '&[data-active]': {
              color: theme.colors['btn-primary'][0],
              backgroundColor: theme.colors['btn-primary-label'][0],
            },

            '&[data-active]:not([data-disabled]):hover': {
              color: theme.colors['btn-primary'][0],
              backgroundColor: theme.colors['btn-primary-label'][0],
            },
          },
        }),
      },
    },
  };
};
