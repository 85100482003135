import { Carousel } from '@mantine/carousel';
import { Box, createStyles, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconZoomInFilled } from '@tabler/icons-react';
import Image from 'next/future/image';
import { useMemo, useState } from 'react';

import ProductPlaceholder from '../../assets/product-placeholder.svg';
import { IProduct } from '../../typings';
import { imageLoader } from '../../utils';
import ZoomableImage from './ZoomableImage';

const useStyles = createStyles((theme) => {
  return {
    slide: {
      cursor: 'pointer',
      position: 'relative',
      minWidth: 102,
      height: '100%',
    },
    activeSlide: {
      borderBottom: `2px solid ${theme.colors['borders-and-lines'][0]}`,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    imageContainer: {
      marginBottom: 20,
      position: 'relative',
      width: '100%',
      height: 500,
    },
    image: {
      objectFit: 'contain',
    },
    zoomableImage: {
      '&:hover': {
        cursor: 'zoom-in',
      },
    },
    zoomIcon: {
      padding: 10,
      position: 'absolute',
      bottom: 10,
      right: 10,
      color: theme.colors['dark-gray'][0],
      backgroundColor: theme.white,
      borderRadius: '50%',
    },
  };
});

type Props = {
  withinBuilder?: boolean;
  product: IProduct;
  variantImagesIds?: number[];
  hasZoomableImages: boolean;
  showGalleryOnMobile: boolean;
  isMobile: boolean;
  isFeaturedProduct?: boolean;
};

function SlideContent({
  index,
  image,
  withinBuilder,
  product,
  showZoomIcon,
  withActiveSlideClass,
  onClick,
}: {
  index: number;
  image: { urls: string[] };
  withinBuilder?: boolean;
  product: IProduct;
  showZoomIcon?: boolean;
  withActiveSlideClass?: boolean;
  onClick?: () => void;
}) {
  const { cx, classes } = useStyles();

  return (
    <Box
      className={cx(classes.slide, {
        [classes.activeSlide]: withActiveSlideClass,
      })}
    >
      <Image
        fill
        loader={imageLoader({ urls: image.urls, withinBuilder })}
        sizes="(max-width: 900px) 100vw, 15vw"
        src={image.urls[0]}
        alt={`${product.name} ${index}`}
        className={classes.image}
        priority={index === 0}
        onClick={onClick}
      />
      {showZoomIcon && (
        <IconZoomInFilled size={40} className={classes.zoomIcon} onClick={onClick} />
      )}
    </Box>
  );
}

const ProductGallery = ({
  withinBuilder,
  product,
  variantImagesIds,
  hasZoomableImages,
  showGalleryOnMobile,
  isMobile,
  isFeaturedProduct,
}: Props) => {
  const [opened, { open, close }] = useDisclosure(false);

  const [activeImage, setActiveImage] = useState<string[] | undefined>();
  const [zoomedImage, setZoomedImage] = useState<string[] | undefined>();

  const { cx, classes } = useStyles();

  const galleryImages = useMemo(() => {
    let images = product.imagesUrls || [];

    if (variantImagesIds) {
      const variantImages = images.filter((img) => variantImagesIds.includes(img.id));
      if (variantImages.length > 0) images = variantImages;
    }

    if ((!isMobile || showGalleryOnMobile) && !isFeaturedProduct && images.length > 0)
      setActiveImage(images[0].urls);

    return images.filter((img) => !!img.urls);
  }, [isMobile, product, variantImagesIds]);

  const isActiveImg = (imgUrl: string) => activeImage?.[0] === imgUrl;

  const openZoomModal = (image?: string[]) => {
    setZoomedImage(image);
    open();
  };

  const closeZoomModal = () => {
    setZoomedImage(undefined);
    close();
  };

  return (
    <>
      <Box className={classes.container} mb={isMobile ? 16 : 30}>
        {(!isMobile || showGalleryOnMobile) && !isFeaturedProduct && (
          <Box className={classes.imageContainer}>
            <Image
              fill
              alt={product.name}
              src={activeImage?.[0] || ProductPlaceholder}
              className={cx(classes.image, { [classes.zoomableImage]: hasZoomableImages })}
              priority
              loader={imageLoader({ urls: activeImage, withinBuilder })}
              onClick={() => hasZoomableImages && openZoomModal(activeImage)}
            />
            {isMobile && showGalleryOnMobile && hasZoomableImages && (
              <IconZoomInFilled
                size={40}
                className={classes.zoomIcon}
                onClick={() => openZoomModal(activeImage)}
              />
            )}
          </Box>
        )}

        {(isMobile || isFeaturedProduct || galleryImages.length > 1) && (
          <Carousel
            slideSize={(isMobile && !showGalleryOnMobile) || isFeaturedProduct ? '100%' : '15%'}
            height={(isMobile && !showGalleryOnMobile) || isFeaturedProduct ? 500 : 100}
            align={(isMobile && !showGalleryOnMobile) || isFeaturedProduct ? 'center' : 'start'}
            slideGap={(isMobile && !showGalleryOnMobile) || isFeaturedProduct ? 0 : 15}
            withControls={false}
            withIndicators={(isMobile && !showGalleryOnMobile) || isFeaturedProduct}
            controlSize={25}
            controlsOffset={5}
            dragFree={!isFeaturedProduct}
            draggable
            slidesToScroll={isMobile || isFeaturedProduct ? 1 : 5}
            styles={(theme) => ({
              root: {
                width: '100%',
              },
              indicator: {
                width: 10,
                height: 10,
                borderRadius: 10,
                backgroundColor: theme.colors['light-gray'][0],
                transition: 'backgroundColor 250ms ease',

                '&[data-active]': {
                  width: 20,
                  backgroundColor: theme.colors['btn-primary'][0],
                },
              },
            })}
          >
            {galleryImages.map((img, index) => (
              <Carousel.Slide
                key={img.id}
                onClick={() =>
                  (!isMobile || showGalleryOnMobile) &&
                  !isFeaturedProduct &&
                  setActiveImage(img.urls)
                }
              >
                <SlideContent
                  index={index}
                  withActiveSlideClass={
                    (!isMobile || showGalleryOnMobile) &&
                    !isFeaturedProduct &&
                    isActiveImg(img.urls[0])
                  }
                  product={product}
                  image={img}
                  showZoomIcon={isMobile && !showGalleryOnMobile && hasZoomableImages}
                  onClick={() =>
                    isMobile && !showGalleryOnMobile && hasZoomableImages && openZoomModal(img.urls)
                  }
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        )}
      </Box>

      <Modal
        fullScreen
        withCloseButton={false}
        transitionProps={{ transition: 'fade', duration: 200 }}
        styles={{
          body: { height: '100%', padding: 10 },
          inner: { zIndex: 2000 },
          overlay: { zIndex: 2000 },
        }}
        trapFocus={false}
        opened={opened}
        onClose={closeZoomModal}
      >
        {zoomedImage && (
          <ZoomableImage product={product} image={zoomedImage} onModalClose={closeZoomModal} />
        )}
      </Modal>
    </>
  );
};

export default ProductGallery;
