import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { Slider } from "react-hook-form-mantine";
import { Divider, Flex, Group, Stack, Text, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

import Tab from "./Tab";

import { ITheme, IThemePage } from "shared";
import RadioButton from "../../common/RadioButton";

const GeneralSettingsTab = ({ control }: { control: Control<ITheme<IThemePage>> }) => {
  const { t } = useTranslation("settings");

  return (
    <Tab tab="general" title={t("general.title")} desc={t("general.description")}>
      <Stack spacing={5}>
        <Text size={20} weight={400} color="primary-black">
          {t("general.grid.title")}
        </Text>
        <Stack spacing={10} p={10}>
          <Group position="apart">
            <Text size={14} weight={400} color="high-gray">
              {t("general.grid.mobile_columns")}
            </Text>
            <RadioButton
              data={[
                { value: "1", label: "1 columns" },
                { value: "2", label: "2 columns" },
              ]}
              control={control}
              name="gridMobileColumns"
            />
          </Group>
          <Group position="apart">
            <Text size={14} weight={400} color="high-gray">
              {t("general.grid.desktop_columns")}
            </Text>
            <RadioButton
              data={[
                { value: "2", label: "2 columns" },
                { value: "3", label: "3 columns" },
                { value: "4", label: "4 columns" },
              ]}
              control={control}
              name="gridDesktopColumns"
            />
          </Group>
        </Stack>
      </Stack>
      <Divider my={20} color="light-gray" />
      <Stack spacing={5}>
        <Text size={20} weight={400} color="primary-black">
          {t("general.product_card.title")}
        </Text>
        <Stack spacing={10} p={10}>
          <Group position="apart">
            <Flex gap={5}>
              <Text size={14} weight={400} color="high-gray">
                {t("general.product_card.image_ratio.title")}
              </Text>
              <Tooltip
                label={
                  <Text size={14} weight={300} color="white">
                    {t("general.product_card.image_ratio.desc")}
                  </Text>
                }
                withArrow
                maw={400}
                multiline
                position="right"
              >
                <IconInfoCircle stroke={1.5} size={20} style={{ cursor: "pointer" }} />
              </Tooltip>
            </Flex>
            <RadioButton
              data={[
                { value: "adapt", label: "Adapt to image" },
                { value: "square", label: "Square" },
                { value: "portrait", label: "Portrait" },
              ]}
              control={control}
              name="imageRatio"
            />
          </Group>
          <Group position="apart">
            <Flex gap={5}>
              <Text size={14} weight={400} color="high-gray">
                {t("general.product_card.image_fit.title")}
              </Text>
              <Tooltip
                label={
                  <Text size={14} weight={300} color="white">
                    {t("general.product_card.image_fit.desc")}
                  </Text>
                }
                withArrow
                maw={400}
                multiline
                position="right"
              >
                <IconInfoCircle stroke={1.5} size={20} style={{ cursor: "pointer" }} />
              </Tooltip>
            </Flex>
            <RadioButton
              data={[
                { value: "contain", label: "Contain" },
                { value: "cover", label: "Cover" },
              ]}
              control={control}
              name="imageFit"
            />
          </Group>
        </Stack>
      </Stack>
      <Divider my={20} color="light-gray" />
      <Stack spacing={5}>
        <Text size={20} weight={400} color="primary-black">
          {t("general.others.title")}
        </Text>
        <Group p={10} position="apart">
          <Flex gap={5}>
            <Text size={14} weight={400} color="high-gray">
              {t("general.others.radius.title")}
            </Text>
            <Tooltip
              label={
                <Text size={14} weight={300} color="white">
                  {t("general.others.radius.desc")}
                </Text>
              }
              withArrow
              maw={400}
              multiline
              position="right"
            >
              <IconInfoCircle stroke={1.5} size={20} style={{ cursor: "pointer" }} />
            </Tooltip>
          </Flex>
          <Group w="50%" position="right" spacing={25}>
            <Text size={12} weight={700} color="high-gray">
              0 px
            </Text>
            <Slider w="70%" size="xl" color="secondary-magenta" name="radius" control={control} />
            <Text size={12} weight={700} color="high-gray">
              100 px
            </Text>
          </Group>
        </Group>
      </Stack>
    </Tab>
  );
};

export default GeneralSettingsTab;
