import { Box } from '@mantine/core';
import Link from 'next/link';

import { Image } from '../../../typings/index';

type Props = {
  logoImage: Image;
  logoUrl?: string;
  redirectUrl?: string;
  companyName?: string;
};

export default function Logo({ logoImage, logoUrl, redirectUrl, companyName }: Props) {
  return (
    <Link href={redirectUrl || '/'} passHref>
      <Box sx={{ maxWidth: '50%' }} w="fit-content" h={50} component="a">
        <img
          style={{ objectFit: 'contain' }}
          height={50}
          src={logoImage.value[0] || logoUrl}
          alt={companyName}
        />
      </Box>
    </Link>
  );
}
