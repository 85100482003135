import { Box, Select, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { TFunction } from 'i18next';
import { useEffect } from 'react';

import { ISelectItem } from '../../typings';
import ProductOptionItem from './ProductOptionItem';

type Props = {
  isMobile: boolean;
  option: string;
  values: ISelectItem[];
  value?: string;
  setValue: (value: string) => void;
  t: TFunction;
};

const OptionSelect = ({ isMobile, option, values, value, setValue, t }: Props) => {
  // Select value if there is only one option
  useEffect(() => {
    const validOptions = values.filter((v) => !v.isDisabled);
    if (validOptions.length === 1 && !value) setValue(validOptions[0].value);
  }, [values, setValue, value]);

  return (
    <Box mb={20}>
      <Text mb={15} size={16} weight={700} color="headings-and-links">
        {option}
      </Text>
      <Select
        size="md"
        rightSection={<IconChevronDown size="20" />}
        rightSectionWidth={53}
        dropdownPosition={isMobile ? 'top' : 'flip'}
        value={value}
        data={values.map((item) => ({ ...item, isMobile, t }))}
        onChange={setValue}
        itemComponent={ProductOptionItem}
      />
    </Box>
  );
};

export default OptionSelect;
