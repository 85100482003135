import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { Box } from "@mantine/core";
import ColorsTab from "../../components/builder/settings/ColorsTab";
import GeneralSettingsTab from "../../components/builder/settings/GeneralSettingsTab";
import LogosTab from "../../components/builder/settings/LogosTab";
import TypographyTab from "../../components/builder/settings/TypographyTab";
import LabelsTab from "../../components/builder/settings/LabelsTab";
import { SettingsTab } from "../../typings";
import { ITheme, IThemePage } from "shared";

const Settings = () => {
  const { activeTab } = useParams();
  const { control, watch, setValue } = useFormContext<ITheme<IThemePage>>();

  const isActiveTab = (tab: SettingsTab) => {
    return activeTab === tab;
  };

  return (
    <Box m={30} w="auto">
      {isActiveTab("general") && <GeneralSettingsTab control={control} />}
      {isActiveTab("colors") && <ColorsTab control={control} />}
      {isActiveTab("typography") && <TypographyTab control={control} />}
      {isActiveTab("logos") && <LogosTab watch={watch} setValue={setValue} />}
      {isActiveTab("labels") && <LabelsTab control={control} />}
    </Box>
  );
};

export default Settings;
