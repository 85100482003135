import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionIcon, DefaultMantineColor, Group, Text } from "@mantine/core";
import {
  ICompany,
  ITheme,
  IThemePage,
  INavigationComponent,
  ZammitComponent,
  hasFeature,
  INavigationItem,
  ValidLocales,
} from "shared";
import { IconSearch, IconShoppingBag, IconHeart, IconUser } from "@tabler/icons-react";

import Icon from "../../common/Icon";

import { useNavigationByIds } from "../../../hooks/queries/useNavigationByIds";
import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";

type Props = {
  company: ICompany;
  component: INavigationComponent;
  theme: ITheme<IThemePage>;
  isSideBarOpen?: boolean;
  toggleSideBar?: () => void;
};

function Header({
  company,
  theme,
  header,
  data,
  isSideBarOpen,
  toggleSideBar,
}: {
  company: ICompany;
  theme: ITheme<IThemePage>;
  header: INavigationComponent;
  data?: INavigationItem[];
  isSideBarOpen?: boolean;
  toggleSideBar?: () => void;
}) {
  const { t } = useTranslation(["sections"]);
  const { isMobile, locale } = useBuilderQuery();
  const oppositeLocale = locale === "en" ? "ar" : "en";

  const headerTxtColor = header.propValues.find((prop) => prop.key === "txtColor")?.value as DefaultMantineColor;
  const headerStickyTxtColor = header.propValues.find((prop) => prop.key === "stickyTxtColor")
    ?.value as DefaultMantineColor;
  const sideMenuTextColor =
    (header.propValues.find((prop) => prop.key === "sideMenuTextColor")?.value as DefaultMantineColor) ||
    headerTxtColor;

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const parent = document.querySelector(".scroll-container");

      if (parent) {
        setScrolled(parent.scrollTop > 50);
      }
    };

    document.addEventListener("scroll", handleScroll, { passive: true, capture: true });

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ZammitComponent
      company={company}
      component={{ ...header, data }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
      extraProps={{
        isSideBarOpen,
        toggleSideBar,
      }}
    >
      <Group
        id="aside-children"
        spacing={0}
        style={{ flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "flex-start" : "center" }}
      >
        {isMobile ? (
          <>
            <Text color={sideMenuTextColor}>{t("account-menu.sign-in")}</Text>
            <Text color={sideMenuTextColor}>{t("account-menu.register")}</Text>
            {company && hasFeature(company, "bilingual") && <Text color={sideMenuTextColor}>عربي | English</Text>}
          </>
        ) : (
          <>
            <ActionIcon size={isMobile ? "md" : "lg"} variant="transparent" title="language">
              <Icon style={{ cursor: "pointer" }} icon={oppositeLocale as ValidLocales} size={isMobile ? 20 : 22} />
            </ActionIcon>
            <ActionIcon size={isMobile ? "md" : "lg"} variant="transparent" title="account">
              <IconUser
                strokeWidth={1}
                size={isMobile ? 22 : 24}
                color={scrolled ? headerStickyTxtColor : headerTxtColor}
              />
            </ActionIcon>
          </>
        )}
      </Group>
      <Group id="header-children" spacing={0}>
        <ActionIcon size={isMobile ? "md" : "lg"} variant="transparent" title="search">
          <IconSearch
            color={scrolled ? headerStickyTxtColor : headerTxtColor}
            strokeWidth={1}
            size={isMobile ? 22 : 24}
          />
        </ActionIcon>
        {company && !hasFeature(company, "use_website_as_catalogue") && (
          <ActionIcon size={isMobile ? "md" : "lg"} variant="transparent" title="wishlist">
            <IconHeart
              color={scrolled ? headerStickyTxtColor : headerTxtColor}
              strokeWidth={1}
              size={isMobile ? 22 : 24}
            />
          </ActionIcon>
        )}
        {company && !hasFeature(company, "use_website_as_catalogue") && hasFeature(company, "wishlist") && (
          <ActionIcon size={isMobile ? "md" : "lg"} variant="transparent" title="cart">
            <IconShoppingBag
              color={scrolled ? headerStickyTxtColor : headerTxtColor}
              strokeWidth={1}
              size={isMobile ? 22 : 24}
            />
          </ActionIcon>
        )}
      </Group>
    </ZammitComponent>
  );
}

const ComponentWithNavigationModel = ({ company, component, theme, isSideBarOpen, toggleSideBar }: Props) => {
  const { t } = useTranslation(["sections"]);
  const { isMobile, locale } = useBuilderQuery();
  const { data } = useNavigationByIds({
    component: component.key as "header" | "footer",
    ids: component.modelIds,
  });

  return component.key === "header" ? (
    <Header
      company={company}
      theme={theme}
      header={component}
      data={data}
      isSideBarOpen={isSideBarOpen}
      toggleSideBar={toggleSideBar}
    />
  ) : (
    <ZammitComponent
      company={company}
      component={{ ...component, data }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
      extraProps={{
        newsletterDefaultTitle: t("newsletter.input"),
      }}
    />
  );
};

export default ComponentWithNavigationModel;
