import { Badge, Box, createStyles } from '@mantine/core';

import { ISelectItem } from '../../typings';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '15px',
  },
  badge: {
    height: '45px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    borderColor: theme.colors['input-border'][0],
    color: theme.colors['input-text'][0],
    backgroundColor: theme.colors['input-bg'][0],
  },
  badgeMobile: {
    height: '35px',
    padding: '10px 15px',
    fontSize: '14px',
  },
  selected: {
    borderColor: theme.colors['input-border'][0],
    color: theme.colors['input-bg'][0],
    backgroundColor: theme.colors['input-text'][0],
  },
  unselectable: {
    cursor: 'not-allowed',
  },
}));

type Props = {
  isMobile: boolean;
  value?: string;
  sizes: ISelectItem[];
  onSelect: (size: string) => void;
};

const SizeBadges = ({ isMobile, value, sizes, onSelect }: Props) => {
  const { cx, classes } = useStyles();

  return (
    <Box className={classes.container}>
      {sizes.map((size) => (
        <Badge
          key={size.label}
          variant="outline"
          color="high-gray"
          radius={5}
          className={cx(classes.badge, {
            [classes.badgeMobile]: isMobile,
            [classes.selected]: value && value === size.value,
            [classes.unselectable]: size.isDisabled,
          })}
          onClick={() => onSelect(size.value)}
        >
          {size.label}
        </Badge>
      ))}
    </Box>
  );
};

export default SizeBadges;
