import { Badge, Box, createStyles } from '@mantine/core';
import { parseToRgba } from 'color2k';

import { ISelectItem } from '../../typings';

const parseColor = (color?: string) => {
  if (!color) return '#000';

  try {
    const parsedColor = parseToRgba(color);
    return `rgba(${parsedColor.join(', ')})`;
  } catch {
    return '#000';
  }
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    gap: 10,
  },
  selectContainer: {
    gap: 15,
    flexWrap: 'wrap',
  },
  badgeContainer: {
    display: 'flex',
    position: 'relative',
    padding: 2,
    borderRadius: '50%',
    boxShadow: `0 0 0 1px ${theme.colors['input-border'][0]}`,
  },
  badge: {
    width: 30,
    height: 30,

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 24,
      height: 24,
    },
  },
  selectBadge: {
    width: 50,
    height: 50,
    cursor: 'pointer',

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 35,
      height: 35,
    },
  },
  unselectable: {
    cursor: 'not-allowed',
    opacity: 0.3,

    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 1,
      backgroundColor: theme.colors['input-border'][0],
      top: '50%',
      left: 0,
      transform: 'rotate(-45deg)',
    },
  },
}));

type Props = {
  isMobile: boolean;
  selectable?: boolean;
  value?: string;
  colors: ISelectItem[];
  onSelect?: (color: string) => void;
};

export const ColorBadges = ({ isMobile, selectable, value, colors, onSelect }: Props) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.container, {
        [classes.selectContainer]: selectable,
      })}
    >
      {colors.map((color) => {
        const isSelected = value === color.value;
        const mainColor = parseColor(color.value);

        return (
          <Box
            key={color.label}
            className={classes.badgeContainer}
            style={isSelected ? { boxShadow: `0 0 0 2px ${mainColor}` } : {}}
          >
            <Badge
              variant="filled"
              className={cx({
                [classes.badge]: !selectable,
                [classes.selectBadge]: selectable,
                [classes.unselectable]: color.isDisabled,
              })}
              title={color.label}
              styles={{
                root: {
                  backgroundColor: mainColor,
                },
                inner: {
                  display: 'flex',
                },
              }}
              onClick={() => onSelect && onSelect(color.value)}
            ></Badge>
          </Box>
        );
      })}
    </Box>
  );
};
