import { Box, createStyles } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { IProduct } from '../../typings';
import { Button } from '../common/Button';

const useStyles = createStyles((theme) => ({
  imageContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'auto',
  },
  image: {
    position: 'relative',
    top: 0,
    left: 0,
    width: 'auto',
    height: 'auto',
    minWidth: '100%',
    objectFit: 'contain',
    display: 'block',
  },
  xIcon: {
    padding: 2,
    position: 'fixed',
    top: 15,
    right: 15,
    zIndex: 1,
    borderRadius: '50%',
    color: theme.colors['dark-gray'][0],
    backgroundColor: theme.white,
  },
}));

type Props = {
  product: IProduct;
  image: string[];
  onModalClose?: () => void;
};

export default function ZoomableImage({ product, image, onModalClose }: Props) {
  const { classes } = useStyles();

  return (
    <Box className={classes.imageContainer}>
      <IconX size={20} className={classes.xIcon} onClick={onModalClose} />
      <img
        src={image[image.length - 1]}
        alt={product.name}
        className={classes.image}
        // @ts-ignore
        fetchPriority="high"
      />
    </Box>
  );
}
