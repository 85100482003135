import { Box, Select, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { TFunction } from 'i18next';
import { useEffect } from 'react';

import { ISelectItem } from '../../typings';
import { ColorBadges } from '../common/ColorBadges';
import ProductOptionItem from './ProductOptionItem';

type Props = {
  isMobile: boolean;
  title: string;
  showAsDropdown: boolean;
  colors: ISelectItem[];
  value?: string;
  setValue: (value: string) => void;
  t: TFunction;
};

const ColorOptions = ({ isMobile, title, showAsDropdown, colors, value, setValue, t }: Props) => {
  // Select value if there is only one option
  useEffect(() => {
    const validOptions = colors.filter((c) => !c.isDisabled);
    if (validOptions.length === 1 && !value) setValue(validOptions[0].value);
  }, [colors, setValue, value]);

  return (
    <Box my={20}>
      <Text mb={15} size={isMobile ? 14 : 16} weight={700} color="headings-and-links">
        {title}
      </Text>
      {showAsDropdown ? (
        <Select
          size="md"
          rightSection={<IconChevronDown size="20" />}
          rightSectionWidth={53}
          dropdownPosition={isMobile ? 'top' : 'flip'}
          data={colors.map((item) => ({ ...item, isMobile, t }))}
          value={value}
          onChange={setValue}
          itemComponent={ProductOptionItem}
        />
      ) : (
        <ColorBadges
          isMobile={isMobile}
          selectable={true}
          value={value}
          colors={colors}
          onSelect={setValue}
        />
      )}
    </Box>
  );
};

export default ColorOptions;
