import { Text } from '@mantine/core';
import { TFunction } from 'i18next';

import { IPurchaseVariant, IVariant } from '../../typings';

type Props = {
  isTracked: boolean;
  hideItemsLeft: boolean;
  variant?: IVariant | IPurchaseVariant;
  quantitySelected: number;
  text: string;
  t: TFunction;
};

export const ItemsLeftText = ({
  isTracked,
  hideItemsLeft,
  variant,
  quantitySelected,
  text,
  t,
}: Props) => {
  if (!variant || hideItemsLeft || !isTracked) return null;

  if (variant.quantity === 1) return <Text color="dark-red">{t('last-piece')} </Text>;

  return (
    <Text mb={15}>
      {variant.quantity - quantitySelected} {text}
    </Text>
  );
};
