import { Box, Burger, Group } from '@mantine/core';
import Link from 'next/link';
import { Children } from 'react';

import { HeaderBasicProps } from '../../../typings/Header';
import SocialMediaIcons from '../../common/SocialMediaIcons';

const MobileHeader = ({
  theme,
  logoImage,
  stickyHeader,
  txtColor,
  stickyTxtColor,
  socialMediaIconsColor,
  showSocialMediaIcons,
  company,
  children,
  scrolled,
  isSideMenuBar,
  toggleSideBar,
}: HeaderBasicProps) => {
  return (
    <>
      {showSocialMediaIcons ? (
        <Group position="center" p={15}>
          <SocialMediaIcons company={company} color={socialMediaIconsColor} />
        </Group>
      ) : null}
      <Group position="apart" px={10} py={10}>
        <Burger
          size={18}
          opened={isSideMenuBar}
          title="Open navigation"
          aria-label="Open navigation"
          color={stickyHeader === 'Always' && scrolled ? stickyTxtColor : txtColor}
          onClick={(e) => {
            e.stopPropagation();
            toggleSideBar();
          }}
        />
        <Link href={theme.landingPageUrl || '/'} passHref>
          <Box
            w="fit-content"
            h={45}
            sx={{
              maxWidth: 100,
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
            component="a"
          >
            <img
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              height={45}
              src={logoImage.value[0] || theme.logoUrl}
              alt={company?.name}
            />
          </Box>
        </Link>

        {Children.toArray(children).find((child: any) => child.props.id === 'header-children')}
      </Group>
    </>
  );
};

export default MobileHeader;
