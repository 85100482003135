import { ActionIcon, useMantineTheme } from '@mantine/core';
import { IconHeart, IconHeartFilled } from '@tabler/icons-react';

import { ICompany, IProduct } from '../../typings';

type Props = {
  id: number;
  withinCard?: boolean;
  isOnWishlist: (id: number) => boolean;
  toggleWishlist: (params: { id: number; product: IProduct; company?: ICompany }) => void;
  product: IProduct;
  company?: ICompany;
};

export const ProductWishlistIcon = ({
  id,
  withinCard,
  isOnWishlist,
  toggleWishlist,
  product,
  company,
}: Props) => {
  const mantineTheme = useMantineTheme();

  return (
    <ActionIcon
      p={5}
      size={withinCard ? 30 : 42}
      maw={withinCard ? 30 : 42}
      variant="filled"
      color="btn-secondary"
      onClick={(e: any) => {
        e.preventDefault();
        toggleWishlist({ id, product, company });
      }}
    >
      {isOnWishlist(id) ? (
        // we have to use style instead of color & fill due to bug in tabler, https://github.com/tabler/tabler-icons/issues/893
        <IconHeartFilled
          size={withinCard ? 24 : 36}
          style={{ color: mantineTheme.colors['btn-secondary-label'][0] }}
        />
      ) : (
        <IconHeart
          size={withinCard ? 24 : 36}
          style={{ color: mantineTheme.colors['btn-secondary-label'][0] }}
        />
      )}
    </ActionIcon>
  );
};
