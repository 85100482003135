import {
  Box,
  Divider,
  Flex,
  Footer as MantineFooter,
  FooterProps as MantineFooterProps,
  Group,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import Link from 'next/link';
import { useState } from 'react';

import { ICompany, Image, INavigationItem, IPage, ITheme, ValidLocales } from '../../../typings';
import { hasEnabledCompanyLinks, important } from '../../../utils';
import { Button } from '../../common/Button';
import SocialMediaIcons from '../../common/SocialMediaIcons';
import { FreeText } from '../FreeText';
import NavigationList from './NavigationList';
import PaymentMethodsIcons from './PaymentMethodsIcons';

export interface FooterProps extends MantineFooterProps {
  theme: ITheme<IPage>;
  logoImage: Image;
  caption: string;
  txtColor?: string;
  bgColor?: string;
  socialMediaIconsColor?: string;
  showSocialMediaIcons: boolean;
  showPaymentIcons: boolean;
  company: ICompany;
  data?: INavigationItem[];
  isMobile?: boolean;
  locale?: ValidLocales;
  newsletterDefaultTitle: string;
  newsletterTitle: string;
  enableNewsletterSubscription?: boolean;
  subscribeToNewsletter?: ({ email, onSuccess }: { email: string; onSuccess: () => void }) => void;
  componentChildren?: React.ReactNode;
}

export const Footer = ({
  theme,
  logoImage,
  caption,
  txtColor,
  bgColor,
  height = 'fit-content',
  socialMediaIconsColor,
  showSocialMediaIcons,
  showPaymentIcons,
  company,
  data,
  isMobile,
  newsletterDefaultTitle,
  newsletterTitle,
  enableNewsletterSubscription,
  componentChildren,
  locale,
  subscribeToNewsletter,
  ...props
}: FooterProps) => {
  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const showSocialMediaIconsFlag = showSocialMediaIcons && hasEnabledCompanyLinks(company);

  return (
    <MantineFooter
      height={height}
      p={50}
      bg={bgColor}
      sx={{ position: 'relative', border: 0, ...props.sx }}
      {...props}
    >
      <Group
        sx={{ flexDirection: isMobile ? 'column' : 'row' }}
        position={isMobile ? 'center' : 'apart'}
      >
        <Box sx={{ flexGrow: important(1) }}>
          <Box component={Link} href="/">
            <Group
              sx={{ maxWidth: isMobile ? '100%' : '50%' }}
              w={isMobile ? '100%' : 'fit-content'}
              position={isMobile ? 'center' : 'apart'}
            >
              <img
                height={50}
                style={{
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                src={logoImage.value[0] || theme.logoUrl}
                alt={company.name}
              />
            </Group>
          </Box>
          {caption ? (
            <FreeText
              p={0}
              pt={10}
              text={caption}
              textAlign={isMobile ? 'center' : 'start'}
              maw={isMobile ? '100%' : '60%'}
            />
          ) : null}
        </Box>
        <Stack>
          {enableNewsletterSubscription && (
            <Stack spacing={10}>
              <Text
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: txtColor,
                  textAlign: isMobile ? 'center' : 'start',
                }}
              >
                {newsletterTitle || newsletterDefaultTitle}
              </Text>
              <Flex align="flex-start">
                <Stack align="start" spacing={5}>
                  <TextInput
                    size="md"
                    styles={(mantineTheme) => ({
                      input: {
                        borderRadius: `${theme.radius}px 0 0 ${theme.radius}px`,
                        borderColor: mantineTheme.colors['btn-primary-border'][0],
                      },
                    })}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setShowEmailError(false);
                    }}
                  />

                  {showEmailError && (
                    <Text
                      sx={{
                        fontSize: 12,
                        color: 'red',
                        marginLeft: 5,
                      }}
                    >
                      {locale === 'ar' ? 'البريد الالكتروني مطلوب!' : 'Email is required!'}
                    </Text>
                  )}
                </Stack>

                <Button
                  variant="filled"
                  label={locale === 'ar' ? 'اشترك' : 'Subscribe'}
                  containerStyle={{ width: 'fit-content', marginLeft: -1 }}
                  style={{
                    borderRadius:
                      locale === 'ar'
                        ? `${theme.radius}px 0 0 ${theme.radius}px`
                        : `0 ${theme.radius}px ${theme.radius}px 0`,
                  }}
                  onClick={() => {
                    if (email) {
                      subscribeToNewsletter &&
                        subscribeToNewsletter({
                          email,
                          onSuccess: () => setEmail(''),
                        });
                    } else {
                      setShowEmailError(true);
                    }
                  }}
                />
              </Flex>
            </Stack>
          )}
          {showSocialMediaIconsFlag ? (
            <SocialMediaIcons
              sx={{ alignSelf: !isMobile && caption ? 'flex-end' : 'center' }}
              company={company}
              color={socialMediaIconsColor}
            />
          ) : null}
        </Stack>
      </Group>
      <Divider my={30} color={txtColor} />
      {data && data.length ? (
        <>
          <NavigationList isMobile={isMobile} navItems={data} txtColor={txtColor} />
          <Divider my={30} color={txtColor} />
        </>
      ) : null}
      <Group position={isMobile || !showPaymentIcons ? 'center' : 'apart'}>
        {showPaymentIcons && <PaymentMethodsIcons company={company} />}
        <Text size={12} weight={400} color={txtColor}>
          © {new Date(company?.createdAt || '2020').getFullYear()}, {company.name} Powered by
          Zammit
        </Text>
      </Group>
    </MantineFooter>
  );
};
