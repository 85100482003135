import facebook from "../../assets/icons/facebook.svg";
import x from "../../assets/icons/x.svg";
import instagram from "../../assets/icons/instagram.svg";
import whatsapp from "../../assets/icons/whatsapp.svg";
import behance from "../../assets/icons/behance.svg";
import youtube from "../../assets/icons/youtube.svg";
import tiktok from "../../assets/icons/tiktok.svg";
import pinterest from "../../assets/icons/pinterest.svg";
import email from "../../assets/icons/email.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import telegram from "../../assets/icons/telegram.svg";
import threads from "../../assets/icons/threads.svg";
import snapchat from "../../assets/icons/snapchat.svg";
import ar from "../../assets/icons/ar.svg";
import en from "../../assets/icons/en.svg";
import { Image, ImageProps } from "@mantine/core";

const iconMap = {
  ar,
  en,
  facebook,
  x,
  instagram,
  whatsapp,
  behance,
  youtube,
  tiktok,
  pinterest,
  email,
  linkedin,
  telegram,
  threads,
  snapchat,
} as const;

type ValidIcons = keyof typeof iconMap;
interface Props extends Omit<ImageProps, "src" | "alt"> {
  color?: string;
  className?: string;
  size: string | number;
  icon: ValidIcons;
}

const Icon = ({ color, className, size, icon, ...props }: Props) =>
  icon in iconMap ? (
    <Image className={className} color={color} width={size} height={size} src={iconMap[icon]} alt={icon} {...props} />
  ) : (
    <></>
  );
export default Icon;
