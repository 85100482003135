import { Center, Box } from "@mantine/core";

type Props = { device: "mobile" | "desktop"; children: React.ReactNode };

function DeviceFrame({ device, children }: Props) {
  return (
    <Center>
      <Box
        sx={{
          position: "relative",
          minWidth: "375px",
          width: device === "desktop" ? "100%" : "25%",
          height: "100%",
        }}
        id="modal-portal"
      >
        {children}
      </Box>
    </Center>
  );
}

export default DeviceFrame;
