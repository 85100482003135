import { useMutation, useQueryClient } from "@tanstack/react-query";

import { publishTheme } from "../../api/Theme";
import { ITheme, IThemePage } from "shared";
import { useFormContext } from "react-hook-form";

export const useThemePublish = () => {
  const form = useFormContext<ITheme<IThemePage>>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ theme }: { theme: Partial<ITheme<IThemePage>> }) => publishTheme(theme),
    onSuccess({ gridMobileColumns, gridDesktopColumns, ...updatedTheme }) {
      form?.reset({
        ...updatedTheme,
        gridMobileColumns: String(gridMobileColumns),
        gridDesktopColumns: String(gridDesktopColumns),
      });
      queryClient.invalidateQueries(["themes"]);
      queryClient.setQueryData(["theme", updatedTheme.id], updatedTheme);
    },
  });
};
