import { Box, Flex, Navbar, Stack } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { INavigationItem } from '../../../typings/Navigation';
import { Currency } from '../../../typings/Theme';
import { CurrencyMenu } from './CurrencyMenu';
import MobileNavigationList from './MobileNavigationList';
import { SideBarItemWrapper } from './SideBarItemWrapper';

type Props = {
  isHidden: boolean;
  sideMenuTextColor?: string;
  sideMenuBgColor?: string;
  showCurrencyMenu: boolean;
  currency?: string;
  currencies?: Currency[];
  data?: INavigationItem[];
  children: React.ReactNode;
  withinBuilder?: boolean;
  closeMenu: () => void;
};

export const HeaderSideBar = ({
  isHidden,
  sideMenuTextColor,
  sideMenuBgColor,
  showCurrencyMenu,
  currency = 'EGP',
  currencies,
  data,
  children,
  withinBuilder,
  closeMenu,
}: Props) => {
  return (
    <Navbar
      top={0}
      height={withinBuilder ? '100%' : '100dvh'}
      w="90%"
      withBorder={false}
      bg={sideMenuBgColor}
      pos={withinBuilder ? 'absolute' : 'fixed'}
      style={{
        ...(isHidden ? { display: 'none' } : {}),
      }}
      zIndex={1001}
    >
      <Flex h={80} p={20} align="center">
        <IconX size={24} color={sideMenuTextColor} cursor="pointer" onClick={closeMenu} />
      </Flex>
      {data ? (
        <Box style={{ flex: '1 1 0%', overflowY: 'auto' }}>
          <MobileNavigationList
            txtColor={sideMenuTextColor}
            navItems={data}
            onNavigate={closeMenu}
          />
        </Box>
      ) : null}
      <Stack spacing={0}>
        {children}
        {showCurrencyMenu && currencies && (
          <SideBarItemWrapper>
            <CurrencyMenu color={sideMenuTextColor} currency={currency} currencies={currencies} />
          </SideBarItemWrapper>
        )}
      </Stack>
    </Navbar>
  );
};
