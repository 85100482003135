import { Divider, Flex, Group } from "@mantine/core";

import IconButton from "../IconButton";
import { useBuilderQuery } from "../../../../hooks/custom/useBuilderQuery";
import { useCompany } from "../../../../hooks/queries/useCompany";
import { hasFeature } from "shared";

const devices = ["mobile", "desktop", "fullscreen"] as const;
const locales = ["en", "ar"] as const;

const PreviewControls = () => {
  const { device: activeDevice, locale: activeLocale, setDevice, setLocale } = useBuilderQuery();
  const { data: company } = useCompany();

  return (
    <Group spacing={15}>
      <Flex sx={{ borderRadius: 5 }} p={3} bg="light-gray">
        {devices.map((device) => (
          <IconButton key={device} icon={device} isActive={activeDevice === device} onClick={() => setDevice(device)} />
        ))}
      </Flex>
      {company && hasFeature(company, "bilingual") && (
        <>
          <Divider orientation="vertical" />
          <Flex sx={{ borderRadius: 5 }} p={3} bg="light-gray">
            {locales.map((locale) => (
              <IconButton
                key={locale}
                icon={locale}
                isActive={activeLocale === locale}
                onClick={() => setLocale(locale)}
              />
            ))}
          </Flex>
        </>
      )}
    </Group>
  );
};

export default PreviewControls;
