import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mantine/core";

import Header from "./Header";
import Navbar from "./Navbar";
import { useTheme } from "../../../hooks/queries/useTheme";
import BuilderError from "../../loadingSkeleton/builder/BuilderError";
import SectionsSkeleton from "../../loadingSkeleton/builder/SectionsSkeleton";
import SettingsFormSkeleton from "../../loadingSkeleton/builder/settings/SettingsFormSkeleton";
import PagesSkeleton from "../../loadingSkeleton/builder/themePages/PagesSkeleton";
import AddPageModal from "../themePages/AddPageModal";

import { isCurrentRoute } from "../../../utils";
import { ITheme, IThemePage } from "shared";
import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";

function BuilderLayout() {
  const [isPageModalOpen, setIsPageModalOpen] = useState(false);
  const { device } = useBuilderQuery();

  return (
    <>
      {device !== "fullscreen" && <Navbar openPageModal={() => setIsPageModalOpen(true)} />}
      <Box bg="smoky-white">
        <BuilderContainer isPageModalOpen={isPageModalOpen} closePageModalOpen={() => setIsPageModalOpen(false)} />
      </Box>
    </>
  );
}

function BuilderContainer({
  isPageModalOpen,
  closePageModalOpen,
}: {
  isPageModalOpen: boolean;
  closePageModalOpen: () => void;
}) {
  const params = useParams();

  const themeId = Number(params.theme);
  const { data: theme, isLoading, error, isError } = useTheme(themeId);

  if (isError) return <BuilderError error={error} />;
  if (isLoading) {
    if (isCurrentRoute("sections")) return <SectionsSkeleton />;
    if (isCurrentRoute("settings")) return <SettingsFormSkeleton />;
    if (isCurrentRoute("pages")) return <PagesSkeleton />;
    else return <></>;
  }
  if (!theme) return <></>;

  return <BuilderLayoutForm theme={theme} isPageModalOpen={isPageModalOpen} closePageModalOpen={closePageModalOpen} />;
}

function BuilderLayoutForm({
  theme,
  isPageModalOpen,
  closePageModalOpen,
}: {
  theme: ITheme<IThemePage>;
  isPageModalOpen: boolean;
  closePageModalOpen: () => void;
}) {
  const form = useForm({
    defaultValues: {
      ...theme,
      gridMobileColumns: String(theme.gridMobileColumns),
      gridDesktopColumns: String(theme.gridDesktopColumns),
    },
  });

  return (
    <FormProvider {...form}>
      <Header useForm />
      <Outlet />
      <AddPageModal isOpen={isPageModalOpen} closeModal={closePageModalOpen} />
    </FormProvider>
  );
}

export default BuilderLayout;
