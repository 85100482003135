import { Box, Button, FileButton, Group, Loader, Text } from '@mantine/core';
import { TFunction } from 'i18next';
import { useRef } from 'react';

import { usePrice } from '../../hooks';
import { IProdExtra, ISubExtraOptionAnswer, ValidLocales } from '../../typings';
import { getPriceText } from '../../utils';

const allowedFileTypes = [
  'application/pdf',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/tiff',
  'image/svg+xml',
  'image/webp',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
  'text/plain',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
];

type Props = {
  currency?: string;
  priceDecimalPoints?: number;
  locale?: ValidLocales;
  isLoading?: boolean;
  extra: IProdExtra;
  value?: ISubExtraOptionAnswer<File>;
  setValue: (value: ISubExtraOptionAnswer<File>) => void;
  t: TFunction;
};

const ExtraFile = ({
  currency,
  priceDecimalPoints,
  locale,
  isLoading,
  extra,
  value,
  setValue,
  t,
}: Props) => {
  const { formatPrice } = usePrice({ locale, currency, priceDecimalPoints });

  const resetRef = useRef<() => void>(null);

  const onFileSelect = (file: File) => {
    setValue({
      extraId: extra.id,
      extraOptionId: extra.options[0].id,
      value: file,
    });
  };

  const clearFile = () => {
    setValue({ ...value!, value: null });
    resetRef.current?.();
  };

  return (
    <Box mb={20}>
      <Group mb={15} spacing={15} align="center">
        <Text size={16} weight={700} color="headings-and-links">
          {`${extra.name} (${t('file-size')}) ${
            extra.options[0].priceCents
              ? getPriceText({
                  t,
                  formatPrice,
                  priceCents: extra.options[0].priceCents,
                  isPricePerLetter: extra.options[0].isPricePerLetter,
                })
              : ''
          } ${extra.isRequired ? '*' : ''}`}
        </Text>
        {isLoading && <Loader size={20} />}
      </Group>
      <Group position="left">
        <FileButton
          resetRef={resetRef}
          disabled={!!value?.value}
          onChange={onFileSelect}
          accept={allowedFileTypes.join(',')}
        >
          {(props) => (
            <Button disabled={!!value?.value} {...props}>
              {t('upload-file')}
            </Button>
          )}
        </FileButton>
        <Button disabled={!value} color="dark-red" onClick={clearFile}>
          {t('reset-btn')}
        </Button>
      </Group>
      {value?.value && (
        <Text size="lg" mt={10} color="body-text" weight={500} align="center">
          {t('picked-file', { fileName: value.value.name })}
        </Text>
      )}
    </Box>
  );
};

export default ExtraFile;
