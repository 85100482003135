import { Carousel, CarouselProps } from '@mantine/carousel';
import { Box, Stack } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import { TFunction } from 'i18next';
import { useRef } from 'react';

import {
  ICompany,
  IPage,
  IProduct,
  ISpacing,
  ITheme,
  IThemeComponent,
  ValidLocales,
} from '../../../typings';
import { formatSpacing, hasFeature, isSoldOut } from '../../../utils';
import ProductCard from '../FeaturedCollection/ProductCard';

export interface FeaturedProductsCarouselProps extends CarouselProps {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  company: ICompany;
  data?: IProduct[];
  locale?: ValidLocales;
  isMobile?: boolean;
  caption?: string;
  bgColor?: string;
  txtColor?: string;
  imageScale: string;
  textAlign?: 'start' | 'center' | 'end';
  columns: number;
  mobileColumns: number;
  withAutoRotating?: boolean;
  autoRotatingDelay?: number;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  order: number;
  enableImageHoverEffect?: boolean;
  showSecondaryImageOnHover?: boolean;
  componentChildren: IThemeComponent[];
  isAddToCartLoading?: boolean;
  t: TFunction;
  handleAddToCart: (product: IProduct) => void;
  isOnWishlist: (id: number) => boolean;
  toggleWishlist: (params: { id: number; product: IProduct; company?: ICompany }) => void;
}

export const FeaturedProductsCarousel = ({
  withinBuilder,
  theme,
  caption,
  data,
  company,
  locale,
  isMobile,
  bgColor,
  imageScale,
  txtColor,
  textAlign,
  columns,
  mobileColumns,
  withControls = true,
  withAutoRotating = false,
  autoRotatingDelay = 2000,
  spacing,
  mobileSpacing,
  order,
  enableImageHoverEffect,
  showSecondaryImageOnHover,
  componentChildren,
  isAddToCartLoading,
  t,
  handleAddToCart,
  isOnWishlist,
  toggleWishlist,
  ...props
}: FeaturedProductsCarouselProps) => {
  const autoplay = useRef(Autoplay({ delay: autoRotatingDelay }));

  return (
    <Stack spacing={20} bg={bgColor} sx={{ ...formatSpacing(isMobile ? mobileSpacing : spacing) }}>
      {caption ? <Box w="100%" dangerouslySetInnerHTML={{ __html: caption }} /> : null}
      <Carousel
        maw="100%"
        bg={bgColor}
        slideSize={isMobile ? `${100 / mobileColumns}%` : `${100 / columns}%`}
        slideGap="lg"
        align="start"
        dragFree
        withControls={withControls}
        withKeyboardEvents
        plugins={withAutoRotating ? [autoplay.current] : undefined}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        {...props}
      >
        {data?.map((product) => (
          <Carousel.Slide key={product.id}>
            <ProductCard
              withinBuilder={withinBuilder}
              theme={theme}
              product={product}
              imageScale={imageScale}
              isMobile={isMobile}
              company={company}
              locale={locale}
              txtColor={txtColor}
              textAlign={textAlign}
              priority={order < 4}
              enableImageHoverEffect={enableImageHoverEffect}
              showSecondaryImageOnHover={showSecondaryImageOnHover}
              useShopAsCatalogue={hasFeature(company, 'use_shop_as_catalogue')}
              isProductSoldOut={isSoldOut(product)}
              hasAddToCart={hasFeature(company, 'enable_quick_add_to_cart')}
              isAddToCartLoading={isAddToCartLoading}
              handleAddToCart={handleAddToCart}
              isOnWishlist={isOnWishlist}
              toggleWishlist={toggleWishlist}
              t={t}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Stack>
  );
};
