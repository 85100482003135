import { useParams } from "react-router-dom";
import { Box } from "@mantine/core";

import ColorsTabSkeleton from "./ColorsTabSkeleton";
import TypographyTabSkeleton from "./TypographyTabSkeleton";
import LogosTabSkeleton from "./LogosTabSkeleton";
import GeneralTabSkeleton from "./GeneralTabSkeleton";

import { SettingsTab } from "../../../../typings";
import Header from "../../../builder/layout/Header";

const SettingsFormSkeleton = () => {
  const { activeTab } = useParams();

  const isActiveTab = (tab: SettingsTab) => {
    return activeTab === tab;
  };

  return (
    <Box m={30} w="auto">
      <Header />
      {isActiveTab("general") && <GeneralTabSkeleton />}
      {isActiveTab("colors") && <ColorsTabSkeleton />}
      {isActiveTab("typography") && <TypographyTabSkeleton />}
      {isActiveTab("logos") && <LogosTabSkeleton />}
    </Box>
  );
};

export default SettingsFormSkeleton;
