const DesktopIcon = ({ color }: { color: string }) => {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0715 1.35693H1.92864C1.53415 1.35693 1.21436 1.67673 1.21436 2.07122V13.4998C1.21436 13.8943 1.53415 14.2141 1.92864 14.2141H19.0715C19.466 14.2141 19.7858 13.8943 19.7858 13.4998V2.07122C19.7858 1.67673 19.466 1.35693 19.0715 1.35693Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.07164 14.2139L7.64307 17.7853" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.9287 14.2139L13.3573 17.7853" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.21436 17.7856H14.7858" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DesktopIcon;
