import { Button, Flex, Modal, Stack, Text, TextInput } from '@mantine/core';
import { TFunction } from 'i18next';
import { useState } from 'react';

import { IProduct, IVariant } from '../../typings';

type Props = {
  isMobile: boolean;
  product: IProduct;
  selectedVariant: IVariant | null;
  isOpen: boolean;
  onClose: () => void;
  t: TFunction;
  notifyMe: (id: number, email: string) => Promise<void>;
  isNotifyMeLoading: boolean;
  isNotifyMeSuccessful: boolean;
};

const NotifyMeWhenBackInStockModal = ({
  isMobile,
  selectedVariant,
  isOpen,
  onClose,
  t,
  notifyMe,
  isNotifyMeLoading,
  isNotifyMeSuccessful,
}: Props) => {
  const [email, setEmail] = useState('');

  return (
    <Modal
      centered
      opened={isOpen}
      onClose={onClose}
      size="lg"
      padding={20}
      sx={{ zIndex: 2001, position: 'relative' }}
    >
      <Stack spacing={15} mb={20}>
        <Text
          size={isMobile ? 16 : 25}
          weight={500}
          color="headings-and-links"
          style={{ textAlign: 'center', marginBottom: '20px' }}
        >
          {t('notify-me')}
        </Text>
        <Text
          size={isMobile ? 8 : 15}
          weight={500}
          style={{ textAlign: 'center' }}
        >
          {t('notify-me-description')}
        </Text>
        <Flex gap={5} justify="center">
          <TextInput
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            size="sm"
            placeholder={'Email'}
            styles={{
              root: {
                width: '50%',
              },
            }}
          />
          <Button
            size="sm"
            variant="filled"
            loading={isNotifyMeLoading}
            sx={{
              color: 'white',
              backgroundColor: 'black',
              borderColor: 'black',
              width: '20%',
              height: '35px',
            }}
            onClick={() => {
              notifyMe(selectedVariant?.id || -1, email);
            }}
          >
            {t('send')}
          </Button>
        </Flex>
        {isNotifyMeSuccessful && !isNotifyMeLoading && (
          <div
            style={{
              textAlign: 'center',
              width: '50%',
              margin: '0 auto',
            }}
          >
            <p>{t('notify-back-in-stock-success')}</p>
          </div>
        )}
      </Stack>
    </Modal>
  );
};

export default NotifyMeWhenBackInStockModal;
