import { Box, Modal, Text } from '@mantine/core';
import { TFunction } from 'i18next';

import { ICompany } from '../../typings';

type Props = {
  isMobile: boolean;
  company: ICompany;
  isOpen: boolean;
  onClose: () => void;
  t: TFunction;
};

const PoliciesModal = ({ isMobile, company, isOpen, onClose, t }: Props) => {
  return (
    <Modal
      centered
      opened={isOpen}
      onClose={onClose}
      size="auto"
      styles={(theme) => ({
        header: { padding: 20, backgroundColor: theme.colors['page-bg'][0] },
        content: {
          minWidth: isMobile ? '100%' : '50% !important',
          backgroundColor: theme.colors['page-bg'][0],
        },
      })}
      fullScreen={isMobile}
      title={
        <Text size={isMobile ? 16 : 25} weight={500} color="headings-and-links">
          {t('policies-title')}
        </Text>
      }
      sx={{ zIndex: 2001, position: 'relative' }}
    >
      {company.shippingPolicy ? (
        <Box>
          <Text size={isMobile ? 16 : 18} weight={700} mb={5}>
            {t('shipping-title')}
          </Text>
          <Text sx={{ whiteSpace: 'pre-line' }} size={isMobile ? 14 : 16} weight={400} mb={10}>
            {company.shippingPolicy}
          </Text>
        </Box>
      ) : null}
      {company.returnAndRefundPolicy ? (
        <Box>
          <Text size={isMobile ? 16 : 18} weight={700} mb={5}>
            {t('return-refund-title')}
          </Text>
          <Text sx={{ whiteSpace: 'pre-line' }} size={isMobile ? 14 : 16} weight={400}>
            {company.returnAndRefundPolicy}
          </Text>
        </Box>
      ) : null}
    </Modal>
  );
};

export default PoliciesModal;
