import { Box, Button, createStyles, Text } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { TFunction } from 'i18next';

const useStyles = createStyles((theme) => ({
  input: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
    backgroundColor: theme.colors['light-gray'][0],
  },
  button: {
    padding: '5px',
    borderRadius: '5px',
  },
}));

type Props = {
  isMobile: boolean;
  withinDrawer?: boolean;
  isTracked: boolean;
  inventory: number;
  quantity: number;
  min: number;
  max: number;
  displayTitle: boolean;
  setQuantity: (quantity: number) => void;
  t: TFunction;
};

export const QuantityInput = ({
  isMobile,
  withinDrawer,
  isTracked,
  inventory,
  quantity,
  min,
  max,
  displayTitle,
  setQuantity,
  t,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Box>
      {displayTitle ? (
        <Text
          mb={15}
          size={isMobile || withinDrawer ? 14 : 16}
          weight={700}
          color="headings-and-links"
        >
          {t('quantity')}
        </Text>
      ) : null}
      <Box
        px={isMobile || withinDrawer ? 10 : 17}
        py={isMobile || withinDrawer ? 10 : 15}
        sx={{ width: isMobile || withinDrawer ? 110 : 194 }}
        className={classes.input}
      >
        <Button
          variant="filled"
          className={classes.button}
          sx={{
            width: isMobile || withinDrawer ? 23 : 28,
            height: isMobile || withinDrawer ? 23 : 28,
          }}
          disabled={min === quantity}
          onClick={() => setQuantity(quantity - 1)}
        >
          <IconMinus size={16} />
        </Button>
        <Text size={20} weight={900} color="dark-gray">
          {quantity}
        </Text>
        <Button
          variant="filled"
          className={classes.button}
          sx={{
            width: isMobile || withinDrawer ? 23 : 28,
            height: isMobile || withinDrawer ? 23 : 28,
          }}
          disabled={max === quantity || (isTracked && inventory <= quantity)}
          onClick={() => setQuantity(quantity + 1)}
        >
          <IconPlus size={16} />
        </Button>
      </Box>
    </Box>
  );
};
