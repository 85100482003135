import { Badge, Group, Stack, Text } from '@mantine/core';
import { TFunction } from 'i18next';
import { useMemo } from 'react';

import { IProduct, IPurchaseProduct, IVariant, ValidLocales } from '../../typings';
import { getProductPrice, getVariantPrice } from '../../utils';
import { ItemsLeftText } from '../common/ItemsLeftText';
import { OnSaleBadge } from '../common/OnSaleBadge';
import { ProductPrice } from '../common/ProductPrice';

type Props = {
  product: IProduct;
  selectedVariant: IVariant | null;
  pricingDetails: IPurchaseProduct['pricingDetails'] | null;
  hideDiscountTag: boolean;
  hideItemsLeft: boolean;
  isOutOfStock: boolean;
  useShopAsCatalogue: boolean;
  currency?: string;
  priceDecimalPoints?: number;
  locale?: ValidLocales;
  isMobile: boolean;
  t: TFunction;
};

const ProductBasicInfo = ({
  product,
  selectedVariant,
  pricingDetails,
  hideDiscountTag,
  hideItemsLeft,
  isOutOfStock,
  useShopAsCatalogue,
  locale,
  currency,
  priceDecimalPoints,
  isMobile,
  t,
}: Props) => {
  const variantPrice = useMemo(
    () =>
      selectedVariant
        ? getVariantPrice({
            variant: selectedVariant,
            dimensions: pricingDetails ? Object.values(pricingDetails).reduce((a, b) => a * b) : 1,
          })
        : null,
    [selectedVariant, pricingDetails],
  );

  const productPrice = useMemo(() => (product ? getProductPrice(product) : null), [product]);

  return (
    <Stack spacing={20}>
      <Group>
        <Text size={isMobile ? 18 : 25} weight={500} color="headings-and-links">
          {product.name}
        </Text>
        {!useShopAsCatalogue && isOutOfStock && (
          <Badge
            size={isMobile ? 'md' : 'lg'}
            styles={(theme) => ({
              root: {
                fontSize: 12,
                fontWeight: 500,
                color: theme.white,
                backgroundColor: theme.colors['dark-red'][0],
                borderRadius: theme.defaultRadius,
              },
            })}
          >
            {t('sold-out')}
          </Badge>
        )}
      </Group>
      {product.vendor ? (
        <Text size={isMobile ? 12 : 16} weight={300} transform={'uppercase'}>
          {product.vendor}
        </Text>
      ) : null}
      {!useShopAsCatalogue && ((selectedVariant && variantPrice) || productPrice) ? (
        <Group>
          <ProductPrice
            spacing={0}
            price={selectedVariant ? variantPrice! : productPrice!}
            locale={locale}
            currency={currency}
            priceDecimalPoints={priceDecimalPoints}
          />
          {!hideDiscountTag &&
          ((selectedVariant && variantPrice?.salePrice) ||
            (!selectedVariant && productPrice?.salePrice)) ? (
            <OnSaleBadge t={t} />
          ) : null}
        </Group>
      ) : null}
      <ItemsLeftText
        isTracked={product.isTracked}
        hideItemsLeft={hideItemsLeft}
        quantitySelected={0}
        variant={selectedVariant || product.defaultVariant}
        text={t('items-in-stock')}
        t={t}
      />
    </Stack>
  );
};

export default ProductBasicInfo;
