import { Box, Select, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { TFunction } from 'i18next';
import { useEffect } from 'react';

import { ISelectItem } from '../../typings';
import ProductOptionItem from './ProductOptionItem';
import SizeBadges from './SizeBadges';

type Props = {
  isMobile: boolean;
  title: string;
  showAsDropdown: boolean;
  sizes: ISelectItem[];
  value?: string;
  setValue: (value: string) => void;
  t: TFunction;
};
const SizeOptions = ({ isMobile, title, showAsDropdown, sizes, value, setValue, t }: Props) => {
  // Select value if there is only one option
  useEffect(() => {
    const validOptions = sizes.filter((s) => !s.isDisabled);
    if (validOptions.length === 1 && !value) setValue(validOptions[0].value);
  }, [sizes, setValue, value]);

  return (
    <Box mb={20}>
      <Text mb={15} size={isMobile ? 14 : 16} weight={700} color="headings-and-links">
        {title}
      </Text>
      {showAsDropdown ? (
        <Select
          size="md"
          rightSection={<IconChevronDown size="20" />}
          rightSectionWidth={53}
          dropdownPosition={isMobile ? 'top' : 'flip'}
          value={value}
          data={sizes.map((item) => ({ ...item, isMobile, t }))}
          onChange={setValue}
          itemComponent={ProductOptionItem}
        />
      ) : (
        <SizeBadges isMobile={isMobile} value={value} sizes={sizes} onSelect={setValue} />
      )}
    </Box>
  );
};

export default SizeOptions;
