import { Checkbox, Text } from '@mantine/core';
import { TFunction } from 'i18next';

import { usePrice } from '../../hooks';
import { IProdExtra, ISubExtraOptionAnswer, ValidLocales } from '../../typings';
import { getPriceText } from '../../utils';

type Props = {
  currency?: string;
  priceDecimalPoints?: number;
  locale?: ValidLocales;
  extra: IProdExtra;
  value: ISubExtraOptionAnswer<string>[];
  setValue: (value: ISubExtraOptionAnswer<string>[]) => void;
  t: TFunction;
};

const ExtraCheckbox = ({
  currency,
  priceDecimalPoints,
  locale,
  extra,
  value,
  setValue,
  t,
}: Props) => {
  const { formatPrice } = usePrice({ locale, currency, priceDecimalPoints });

  const onChange = (options: string[]) => {
    setValue(
      options.map((option) => ({
        extraId: extra.id,
        extraOptionId: Number(option),
        value: null,
      })),
    );
  };

  const valueArray = value || [];

  const errorText =
    extra.min > 0 && valueArray.length < extra.min ? (
      <Text weight={500} color="dark-red">
        {t('min-extras-error', { min: extra.min, current: valueArray.length })}
      </Text>
    ) : null;

  return (
    <Checkbox.Group
      value={valueArray.map((option) => String(option.extraOptionId))}
      onChange={onChange}
      error={errorText}
      label={
        <Text mb={15} size={16} weight={700} color="headings-and-links">
          {`${extra.name} ${
            extra.max
              ? `(${t('min-max', {
                  min: extra.min,
                  max: extra.max,
                })})`
              : ''
          } ${extra.min > 0 ? '*' : ''}`}
        </Text>
      }
    >
      {extra.options.map((option) => (
        <Checkbox
          key={option.id}
          size="lg"
          disabled={
            valueArray.length >= extra.max && !valueArray.find((v) => v.extraOptionId === option.id)
          }
          value={String(option.id)}
          label={
            <Text>
              {option.name}{' '}
              {option.priceCents
                ? getPriceText({
                    t,
                    formatPrice,
                    priceCents: option.priceCents,
                  })
                : ''}
            </Text>
          }
        />
      ))}
    </Checkbox.Group>
  );
};

export default ExtraCheckbox;
