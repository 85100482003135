import { useTranslation } from "react-i18next";
import { Text, Flex } from "@mantine/core";
import { IconUser } from "@tabler/icons-react";
import {
  Currency,
  hasFeature,
  HeaderSideBar,
  ICompany,
  INavigationItem,
  IThemeComponent,
  SideBarItemWrapper,
} from "shared";

type Props = {
  header?: IThemeComponent;
  isHidden: boolean;
  currency?: string;
  company?: ICompany;
  closeMenu: () => void;
};

export default function SideBar({ header, isHidden, currency = "EGP", company, closeMenu }: Props) {
  const { t } = useTranslation(["sections"]);

  const headerValues = header ? Object.fromEntries(header.propValues.map((prop) => [prop.key, prop.value])) : {};

  return (
    <HeaderSideBar
      withinBuilder
      isHidden={isHidden}
      sideMenuTextColor={headerValues.sideMenuTextColor as string}
      sideMenuBgColor={headerValues.sideMenuBgColor as string}
      showCurrencyMenu={headerValues.showCurrencyMenu as boolean}
      currency={currency}
      currencies={headerValues.currencies as Currency[]}
      data={(header?.data || []) as INavigationItem[]}
      closeMenu={closeMenu}
    >
      {company && !hasFeature(company, "use_website_as_catalogue") && (
        <SideBarItemWrapper>
          <Flex gap={10}>
            <IconUser strokeWidth={1} color={headerValues.sideMenuTextColor as string} />
            <Text color={headerValues.sideMenuTextColor as string}>{t("account-menu.sign-in")}</Text>
          </Flex>
        </SideBarItemWrapper>
      )}
      {company && hasFeature(company, "bilingual") && (
        <SideBarItemWrapper>
          <Text color={headerValues.sideMenuTextColor as string}>عربي | English</Text>
        </SideBarItemWrapper>
      )}
    </HeaderSideBar>
  );
}
