import { Box, Grid, Group, Stack } from '@mantine/core';

import { ICompany, INavigationItem, IPage, ITheme } from '../../../typings';
import { HeaderBasicProps } from '../../../typings/Header';
import { Image } from '../../../typings/index';
import SocialMediaIcons from '../../common/SocialMediaIcons';
import { CurrencyMenu } from './CurrencyMenu';
import Logo from './Logo';
import NavigationList from './NavigationList';

const HeaderGrid = ({
  theme,
  company,
  logoImage,
  desktopLogoPosition,
  txtColor,
  data,
  children,
}: {
  theme: ITheme<IPage>;
  company?: ICompany;
  logoImage: Image;
  txtColor?: string;
  data?: INavigationItem[];
  desktopLogoPosition?: 'top left' | 'top center' | 'middle left' | 'middle center';
  children: React.ReactNode;
}) => {
  switch (desktopLogoPosition) {
    case 'top left':
      return (
        <Stack p={15}>
          <Grid gutter={0}>
            <Grid.Col
              span={2}
              display="flex"
              sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
            >
              <Logo
                logoImage={logoImage}
                logoUrl={theme.logoUrl}
                redirectUrl={theme.landingPageUrl}
                companyName={company?.name}
              />
            </Grid.Col>
            <Grid.Col
              span={5}
              offset={5}
              display="flex"
              style={{ justifyContent: 'flex-end', alignItems: 'center' }}
            >
              {children}
            </Grid.Col>
          </Grid>
          {data ? (
            <NavigationList position="left" txtColor={txtColor} navItems={data} />
          ) : null}
        </Stack>
      );
    case 'top center':
      return (
        <Stack p={15}>
          <Grid gutter={0}>
            <Grid.Col
              span={2}
              offset={5}
              display="flex"
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <Logo
                logoImage={logoImage}
                logoUrl={theme.logoUrl}
                redirectUrl={theme.landingPageUrl}
                companyName={company?.name}
              />
            </Grid.Col>
            <Grid.Col
              span={5}
              display="flex"
              style={{ justifyContent: 'flex-end', alignItems: 'center' }}
            >
              {children}
            </Grid.Col>
          </Grid>
          {data ? (
            <NavigationList position="center" txtColor={txtColor} navItems={data} />
          ) : null}
        </Stack>
      );
    case 'middle center':
      return (
        <Grid gutter={0} p={15}>
          {data ? (
            <Grid.Col span={5} display="flex" sx={{ justifyContent: 'center' }}>
              <NavigationList position="left" txtColor={txtColor} navItems={data} />
            </Grid.Col>
          ) : null}
          <Grid.Col
            span={2}
            offset={data ? 0 : 5}
            display="flex"
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <Logo
              logoImage={logoImage}
              logoUrl={theme.logoUrl}
              redirectUrl={theme.landingPageUrl}
              companyName={company?.name}
            />
          </Grid.Col>
          <Grid.Col
            span={5}
            display="flex"
            style={{ justifyContent: 'flex-end', alignItems: 'center' }}
          >
            {children}
          </Grid.Col>
        </Grid>
      );
    default:
      return (
        <Grid gutter={0} p={15}>
          <Grid.Col
            span={2}
            display="flex"
            sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
          >
            <Logo
              logoImage={logoImage}
              logoUrl={theme.logoUrl}
              redirectUrl={theme.landingPageUrl}
              companyName={company?.name}
            />
          </Grid.Col>
          {data ? (
            <Grid.Col span={7} display="flex" sx={{ justifyContent: 'center' }}>
              <NavigationList position="center" txtColor={txtColor} navItems={data} />
            </Grid.Col>
          ) : null}
          <Grid.Col
            span={3}
            offset={data ? 0 : 7}
            display="flex"
            style={{ justifyContent: 'flex-end', alignItems: 'center' }}
          >
            {children}
          </Grid.Col>
        </Grid>
      );
  }
};

const DesktopHeader = ({
  theme,
  logoImage,
  txtColor,
  stickyTxtColor,
  socialMediaIconsColor,
  showSocialMediaIcons,
  showCurrencyMenu,
  stickyHeader,
  desktopLogoPosition,
  currencies,
  data,
  company,
  children,
  scrolled,
}: HeaderBasicProps) => {
  const showMiniHeader = showSocialMediaIcons || (showCurrencyMenu && currencies);

  return (
    <>
      {showMiniHeader ? (
        <Group position="apart" p={15}>
          {showCurrencyMenu && currencies && (
            <Box
              sx={{
                '&:only-child': {
                  margin: '0 auto',
                },
              }}
            >
              <CurrencyMenu
                color={stickyHeader === 'Always' && scrolled ? stickyTxtColor : txtColor}
                currency={company?.paymentCurrency}
                currencies={currencies}
              />
            </Box>
          )}
          {showSocialMediaIcons && (
            <Box
              sx={{
                '&:only-child': {
                  margin: '0 auto',
                },
              }}
            >
              <SocialMediaIcons company={company} color={socialMediaIconsColor} />
            </Box>
          )}
        </Group>
      ) : null}

      <HeaderGrid
        theme={theme}
        company={company}
        data={data}
        logoImage={logoImage}
        txtColor={stickyHeader === 'Always' && scrolled ? stickyTxtColor : txtColor}
        desktopLogoPosition={desktopLogoPosition}
      >
        {children}
      </HeaderGrid>
    </>
  );
};

export default DesktopHeader;
