import { UnstyledButton, UnstyledButtonProps, useMantineTheme } from "@mantine/core";
import { IconViewportWide } from "@tabler/icons-react";

import DesktopIcon from "../../icons/DesktopIcon";
import MobileIcon from "../../icons/MobileIcon";
import ARIcon from "../../icons/ARIcon";
import ENIcon from "../../icons/ENIcon";

export type Icon = "desktop" | "mobile" | "fullscreen" | "en" | "ar";

const getIconComponent = ({ color, icon }: { color: string; icon: Icon }) => {
  switch (icon) {
    case "desktop":
      return <DesktopIcon color={color} />;
    case "mobile":
      return <MobileIcon color={color} />;
    case "fullscreen":
      return <IconViewportWide strokeWidth={1} color={color} />;
    case "ar":
      return <ARIcon />;
    case "en":
      return <ENIcon />;
  }
};

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement>, UnstyledButtonProps {
  isActive: boolean;
  icon: Icon;
}

const IconButton = ({ isActive, icon, ...props }: Props) => {
  const theme = useMantineTheme();
  return (
    <UnstyledButton
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        height: 30,
        borderRadius: 5,
        textShadow: "0px 0px 8px #fff",
        fontSize: 20,
        backgroundColor: isActive ? theme.colors["secondary-magenta"][0] : theme.colors["light-gray"][0],
      }}
      {...props}
    >
      {getIconComponent({ color: isActive ? "white" : theme.colors["dark-gray"][0], icon })}
    </UnstyledButton>
  );
};

export default IconButton;
